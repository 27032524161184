<template>
  <div class="askdetails">
    <div class="common_page_title">
      <div class="guwidth">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/wenfa' }"
            >问法主页</el-breadcrumb-item
          >
          <el-breadcrumb-item>{{
            lawchilddetail.parentName
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ lawchilddetail.name }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <div class="askdetail_main flex base_w">
      <div class="common_index_leftwidth">
        <div class="askarticbox">
          <p class="title">{{ lawchilddetail.name }}</p>
          <div class="seebox">
            <img src="@/assets/img/kan.png" alt="" />
            <span>{{ lawchilddetail.browses }}次</span>
            <img
              src="@/assets/img/zan.png"
              alt=""
              v-if="lawchilddetail.islike == 0"
              @click.stop="togglelike"
            />
            <img src="@/assets/img/zan1.png" alt="" v-else  @click.stop="togglelike"/>
            <span>{{ lawchilddetail.likeNum }}</span>
          </div>
          <div class="line"></div>
          <div class="details" v-html="lawchilddetail.details"></div>
          <div class="line"></div>
          <div class="btnbox" @click.stop="tourlzixun">提交咨询</div>
        </div>
        <newslabel
          :list="infolist"
          :title="title"
          @refresh="refresh"
        ></newslabel>
      </div>
      <div class="common_index_rightwidth">
        <!-- 热门律师 -->
        <div class="rmls">
          <div class="common_title_box">
            <span class="title">热门律师</span>
            <div class="righttext" @click.stop="tourl('/lvlist')">
              更多
              <img src="@/assets/img/right.png" alt="" class="iconimg" />
            </div>
          </div>
          <hotls :list="Lawlist"></hotls>
        </div>

        <!-- 广告位 -->
        <div class="zixunbo" @click.stop="tourl('/toask')">
          <div class="zaixian">在线咨询 >> </div>
          <img
          src="@/assets/img/icon_guang1.png"
          alt=""
          style="width: 586px; height: 330px; border-radius: 13px; margin-top: 40px; "
        />
        </div>
        

        <!-- 法律资讯 -->
        <div class="flzx">
          <div class="common_title_box">
            <span class="title">法律资讯</span>
          </div>
          <div class="flzxbox">
            <news :type="2" :list="innewlist"></news>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import newslabel from '@/components/newslabel'
import hotls from '@/components/hotls'
import news from '@/components/news'

import apiUrl from '@/api/main'
export default {
  components: { newslabel, hotls, news },
  data() {
    return {
      Lawlist: [],
      innewlist: [],
      lawchilddetail: {},
      infolist: [],
      title: '相关知识标签'
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.lawKnowledgechild();
    this.lsLawyerlist();
    this.getnewlist();
     // 跳转回顶部
     document.documentElement.scrollTop = 0;
  },
  methods: {
    tourl(url) {
      this.$router.push({
        path: url
      })
    },
    // 提交咨询
    tourlzixun(){
      let form = {
        LawKnowledgeType: this.$route.query.parentName
      }
      localStorage.setItem('askinfo', JSON.stringify(form));
      this.$router.push({
        path: '/toask?goback=true'
      })
    },
    refresh() {
      this.lawKnowledgechild()
      document.documentElement.scrollTop = 0 // 返回顶部
    },
    lawKnowledgechild() {
      let data = {
        pId: this.$route.query.pId,
        userId: localStorage.getItem('userId') || ''
      }
      apiUrl.lsLawKnowledgelist(data).then((res) => {
        res.rows.forEach((item) => {
          if (item.id == this.$route.query.id) {
            this.lawchilddetail = item
          } else {
            this.infolist.push(item)
          }
        })
      })
    },
    //切换点赞
    togglelike(){
      if(!localStorage.getItem('userId')){
        return
      }
        let data = {
         		id: this.$route.query.id,
				    userId: localStorage.getItem('userId')
        }
        apiUrl.likelsLawKnowledge(data).then((res) => {
          this.$message({
            message:res.msg,
            type:'none'
          });
          this.lawKnowledgechild();
        })
			},
    // 获取律师列表
    lsLawyerlist() {
      let data = {
        pageNum: 1,
        pageSize: 3,
        goodsIds: this.$route.query.parentName
        // orderByColumn: 'haopinhlv',
        // isAsc: 'desc'
      }
      apiUrl.lsLawyerindex(data).then((res) => {
        res.rows.forEach((element) => {
          if (element.goodsIds) {
            element.goodsIdsArr = element.goodsIds.split(',')
          }
        })
        this.Lawlist = res.rows
      })
    },
    // 最新文章
    getnewlist() {
      let data = {
        pageSize: 5,
        pageNum: 1
        // orderByColumn: 'createTime',
        // isAsc: 'desc'
      }
      apiUrl.lsInformationlist(data).then((res) => {
        this.innewlist = res.rows
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.askdetails {
  .askdetail_main {
    margin-top: 33px;
    padding-bottom: 67px;
    .common_index_leftwidth {
      .askarticbox {
        width: 830px;
        border-radius: 10px;
        border: 1px solid #e6e6e6;
        padding: 27px 24px 38px;
        margin-bottom: 40px;
        .title {
          font-size: 28px;
          font-weight: 500;
          color: #333333;
          text-align: center;
        }
        .seebox {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 13px;
          margin-bottom: 20px;
          img {
            width: 20px;
            height: 20px;
            margin-right: 7px;
            margin-left: 24px;
          }
          span {
            font-size: 14px;
            color: #666666;
            line-height: 20px;
          }
        }
        .line {
          height: 1px;
          border: 1px dotted #dde2f8;
        }
        .details {
          font-size: 13px;
          color: #333333;
          line-height: 25px;
          margin: 20px 0;
        }
        .btnbox {
          width: 200px;
          height: 60px;
          line-height: 60px;
          border-radius: 36px;
          margin: 47px auto 0;
          font-size: 24px;
        }
      }
    }
    // 热门律师
    .rmls {
      // margin-top: 33px;
      .common_title_box {
        margin-bottom: 13px;
      }
    }
    // 法律资讯
    .flzx {
      margin-top: 33px;
      .flzxbox {
        width: 586px;
        min-height: 388px;
        background: #f8f8f8;
        border-radius: 12px;
        padding: 32px;
        margin-top: 16px;
      }
    }
  }
}
</style>